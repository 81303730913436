import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAddSquare = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="navy" fillRule="evenodd">
      <path d="M12.185 8a.755.755 0 0 0-.756.744v2.685H8.744a.749.749 0 0 0-.744.756v-.37c0 .418.341.756.744.756h2.685v2.685c0 .41.336.744.756.744h-.37a.755.755 0 0 0 .756-.744v-2.685h2.685c.41 0 .744-.336.744-.756v.37a.755.755 0 0 0-.744-.756h-2.685V8.744A.749.749 0 0 0 11.815 8h.37z" />
      <path d="M17.34 4a2.66 2.66 0 0 1 2.657 2.527L20 6.66v10.68a2.66 2.66 0 0 1-2.527 2.657L17.34 20H6.66a2.66 2.66 0 0 1-2.657-2.527L4 17.34V6.66a2.66 2.66 0 0 1 2.527-2.657L6.66 4h10.68zm0 1.333H6.66c-.7 0-1.272.54-1.323 1.228l-.004.099v10.68c0 .7.54 1.272 1.228 1.323l.099.004h10.68c.7 0 1.272-.54 1.323-1.228l.004-.099V6.66c0-.7-.54-1.272-1.228-1.323l-.099-.004z" />
    </g>
  </svg>
);
export default SvgAddSquare;
