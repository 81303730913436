import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTermsOfUse = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13 1.001a1.01 1.01 0 0 1 .25.032l.03.008c.03.01.061.02.091.032l.027.011a.914.914 0 0 1 .195.112c.04.03.078.062.114.098l-.093-.082.011.01.082.072 7 7a1.006 1.006 0 0 1 .21.31l.012.026c.012.03.022.06.031.092l.008.03a.921.921 0 0 1 .032.25l-.008-.126.001.009L21 9v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-16a3 3 0 0 1 3-3h7zm-1 2H6a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-10h-6a1 1 0 0 1-.993-.883L12 9.001v-6zm5.585 5L14 4.416v3.585h3.585z"
      fill="navy"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTermsOfUse;
