import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUsers = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path d="M0 0h24v24H0z" />
      <path
        d="M12.917 13.8c2.464 0 4.475 1.91 4.579 4.305l.004.195v1.8c0 .497-.41.9-.917.9a.911.911 0 0 1-.91-.795l-.006-.105v-1.8c0-1.438-1.145-2.613-2.589-2.695l-.161-.005H5.583c-1.464 0-2.661 1.124-2.745 2.541l-.005.159v1.8c0 .497-.41.9-.916.9a.911.911 0 0 1-.91-.795L1 20.1v-1.8c0-2.42 1.945-4.394 4.385-4.496l.198-.004h7.334zM9.25 3C6.719 3 4.667 5.015 4.667 7.5S6.719 12 9.25 12s4.583-2.015 4.583-4.5S11.781 3 9.25 3zm0 1.8C10.769 4.8 12 6.009 12 7.5s-1.231 2.7-2.75 2.7S6.5 8.991 6.5 7.5s1.231-2.7 2.75-2.7zm9.196 9.792a.918.918 0 0 1 1.116-.646c1.955.495 3.342 2.185 3.433 4.15L23 18.3v1.8c0 .497-.41.9-.917.9a.911.911 0 0 1-.91-.795l-.006-.105v-1.8c-.001-1.23-.85-2.304-2.063-2.612a.899.899 0 0 1-.658-1.096zM14.779 3.794a.918.918 0 0 1 1.115-.649c2.028.51 3.447 2.304 3.447 4.36 0 2.055-1.419 3.85-3.447 4.359a.918.918 0 0 1-1.115-.649.899.899 0 0 1 .66-1.095c1.217-.306 2.068-1.382 2.068-2.615 0-1.234-.85-2.31-2.068-2.616a.899.899 0 0 1-.66-1.095z"
        fill="navy"
      />
    </g>
  </svg>
);
export default SvgUsers;
