import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDatabase = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg width={24} height={25} xmlns="http://www.w3.org/2000/svg" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 1.42c1.299 0 2.54.083 3.679.242l.56.086C19.643 2.316 22 3.602 22 5.42v14c0 2.069-3.023 3.443-7.186 3.864l-.602.053-.616.04a29.492 29.492 0 0 1-3.192 0l-.616-.04-.602-.053c-4.064-.411-7.041-1.73-7.18-3.717L2 19.42v-14l.006-.159C2.14 3.527 4.458 2.3 7.76 1.748l.561-.086A26.78 26.78 0 0 1 12 1.42zm2.814 14.864-.602.053-.616.04a29.492 29.492 0 0 1-3.192 0l-.616-.04-.602-.053c-2.1-.212-3.908-.667-5.186-1.33v4.466c0 .227.277.483.773.734l.266.125.3.123.164.061.35.12.38.114c.265.075.548.147.849.214l.464.096.488.09.511.08c.26.037.53.072.806.101l.56.054.579.042a24.952 24.952 0 0 0 3.02 0l.578-.042.561-.054c.276-.03.545-.064.806-.101l.511-.08.488-.09.464-.096c.301-.067.584-.14.848-.214l.381-.115.35-.119.163-.06.301-.124.266-.125c.455-.23.725-.464.767-.677L20 19.42l.001-4.467c-1.278.664-3.087 1.118-5.187 1.33zm5.187-8.337c-.98.51-2.27.896-3.761 1.145l-.561.086A26.78 26.78 0 0 1 12 9.42a26.78 26.78 0 0 1-3.679-.242l-.56-.086C6.27 8.843 4.98 8.457 4 7.948L4 12.42c0 .227.277.483.773.734l.266.125.3.123.164.061.35.12.38.114c.265.075.548.147.849.214l.464.096.488.09.511.08c.26.037.53.072.806.101l.56.054.579.042a24.952 24.952 0 0 0 3.02 0l.578-.042.561-.054c.276-.03.545-.064.806-.101l.511-.08.488-.09.464-.096c.301-.067.584-.14.848-.214l.381-.115.35-.119.163-.06.301-.124.266-.125c.496-.251.773-.507.773-.734zM12 3.42l-.307.002c-.51.006-1.006.028-1.487.064l-.568.048-.551.06c-.452.054-.883.12-1.29.195l-.477.093-.451.1-.425.108C4.937 4.493 4 5.007 4 5.42c0 .413.937.927 2.444 1.33l.425.108.451.1.477.094c.407.074.838.14 1.29.194l.55.06.569.049a24.638 24.638 0 0 0 3.588 0l.568-.049.551-.06c.452-.054.883-.12 1.29-.194l.477-.094.451-.1.425-.108C19.063 6.347 20 5.833 20 5.42c0-.413-.937-.927-2.444-1.33l-.425-.107-.451-.101-.477-.093c-.407-.075-.838-.14-1.29-.195l-.55-.06-.569-.048A24.638 24.638 0 0 0 12 3.42z"
      fill="navy"
      fillRule="nonzero"
      opacity={0.8}
    />
  </svg>
);
export default SvgDatabase;
