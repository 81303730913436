import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDownload = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M21 14a1 1 0 0 1 1 1v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-4a1 1 0 0 1 2 0v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1zM12 2a1 1 0 0 1 1 1v9.585l3.293-3.292a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 0 1.414l-5 5a1.006 1.006 0 0 1-.09.08l.09-.08a1.008 1.008 0 0 1-.674.292L12 16h-.032l-.054-.004L12 16a1.008 1.008 0 0 1-.617-.213 1.016 1.016 0 0 1-.09-.08l-5-5a1 1 0 0 1 1.414-1.414L11 12.585V3a1 1 0 0 1 1-1z"
        fill="navy"
      />
    </g>
  </svg>
);
export default SvgDownload;
