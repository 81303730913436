import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAdd = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="none" fillRule="evenodd">
      <circle cx={12} cy={12} r={12} fill="navy" />
      <path
        fill="#FFF"
        d="M15.8333333,9.16666667 L10.8333333,9.16666667 L10.8333333,4.16666667 C10.8333333,3.70666667 10.4608333,3.33333333 10,3.33333333 C9.53916667,3.33333333 9.16666667,3.70666667 9.16666667,4.16666667 L9.16666667,9.16666667 L4.16666667,9.16666667 C3.70583333,9.16666667 3.33333333,9.54 3.33333333,10 C3.33333333,10.46 3.70583333,10.8333333 4.16666667,10.8333333 L9.16666667,10.8333333 L9.16666667,15.8333333 C9.16666667,16.2933333 9.53916667,16.6666667 10,16.6666667 C10.4608333,16.6666667 10.8333333,16.2933333 10.8333333,15.8333333 L10.8333333,10.8333333 L15.8333333,10.8333333 C16.2941667,10.8333333 16.6666667,10.46 16.6666667,10 C16.6666667,9.54 16.2941667,9.16666667 15.8333333,9.16666667"
        transform="translate(2 2)"
      />
    </g>
  </svg>
);
export default SvgAdd;
