import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgEdit = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11 3.121a1 1 0 0 1 .117 1.994L11 5.12H4a1 1 0 0 0-.993.884L3 6.12v14a1 1 0 0 0 .883.994L4 21.12h14a1 1 0 0 0 .993-.883L19 20.12v-7a1 1 0 0 1 1.993-.116l.007.116v7a3 3 0 0 1-2.824 2.995l-.176.005H4a3 3 0 0 1-2.995-2.823L1 20.12v-14a3 3 0 0 1 2.824-2.995L4 3.121h7zm6.793-1.207a3.121 3.121 0 0 1 4.414 4.414l-9.5 9.5a1 1 0 0 1-.464.263l-4 1A1 1 0 0 1 7.03 15.88l1-4a1 1 0 0 1 .263-.465zm3 1.414a1.121 1.121 0 0 0-1.586 0l-9.304 9.304-.529 2.115 2.114-.529 9.305-9.304a1.122 1.122 0 0 0 .087-1.488z"
      fill="navy"
      fillRule="nonzero"
    />
  </svg>
);
export default SvgEdit;
