import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCalendar = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="navy"
      fillRule="evenodd"
      d="M14,0 C14.5128358,0 14.9355072,0.38604019 14.9932723,0.883378875 L15,1 L15,2 L17,2 C18.5976809,2 19.9036609,3.24891996 19.9949073,4.82372721 L20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,5 C0,3.34314575 1.34314575,2 3,2 L5,2 L5,1 C5,0.44771525 5.44771525,0 6,0 C6.51283584,0 6.93550716,0.38604019 6.99327227,0.883378875 L7,1 L7,2 L13,2 L13,1 C13,0.44771525 13.4477153,0 14,0 Z M18,10 L2,10 L2,19 C2,19.5128358 2.38604019,19.9355072 2.88337887,19.9932723 L3,20 L17,20 C17.5522847,20 18,19.5522847 18,19 L18,10 Z M5,4 L3,4 C2.44771525,4 2,4.44771525 2,5 L2,8 L18,8 L18,5 C18,4.48716416 17.6139598,4.06449284 17.1166211,4.00672773 L17,4 L15,4 L15,5 C15,5.55228475 14.5522847,6 14,6 C13.4871642,6 13.0644928,5.61395981 13.0067277,5.11662113 L13,5 L13,4 L7,4 L7,5 C7,5.55228475 6.55228475,6 6,6 C5.48716416,6 5.06449284,5.61395981 5.00672773,5.11662113 L5,5 L5,4 Z"
      transform="translate(2 1)"
    />
  </svg>
);
export default SvgCalendar;
