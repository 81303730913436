import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgArrowCircleLeft = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="none" fillRule="evenodd">
      <path
        d="M16 1.333C7.9 1.333 1.333 7.9 1.333 16S7.9 30.667 16 30.667 30.667 24.1 30.667 16 24.1 1.333 16 1.333z"
        fill="navy"
      />
      <path
        d="m15.057 9.724-5.333 5.333-.018.019c-.02.02-.04.041-.057.063l.075-.082a1.347 1.347 0 0 0-.373.723 1.854 1.854 0 0 0-.017.177v.086l.002.046L9.333 16a1.345 1.345 0 0 0 .235.755 1.457 1.457 0 0 0 .156.188l5.333 5.333a1.333 1.333 0 1 0 1.886-1.886l-3.055-3.057h7.445a1.333 1.333 0 0 0 0-2.666h-7.446l3.056-3.058c.48-.48.517-1.237.11-1.76l-.11-.125a1.333 1.333 0 0 0-1.886 0z"
        fill="#FFF"
      />
    </g>
  </svg>
);
export default SvgArrowCircleLeft;
