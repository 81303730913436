import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgArrowCircleRight = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="none" fillRule="evenodd">
      <path d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1z" fill="navy" />
      <path
        d="m12.707 7.293 4 4c.029.028.055.059.08.09l-.08-.09a1.008 1.008 0 0 1 .292.675L17 12v.033l-.004.052L17 12a1.008 1.008 0 0 1-.213.617l-.007.008-.073.082-4 4a1 1 0 0 1-1.414-1.414L13.585 13H8a1 1 0 0 1 0-2h5.585l-2.292-2.293a1 1 0 0 1-.083-1.32l.083-.094a1 1 0 0 1 1.414 0z"
        fill="#FFF"
      />
    </g>
  </svg>
);
export default SvgArrowCircleRight;
